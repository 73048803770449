.uniqcardContainer {
	display: block;
	position: fixed;
	z-index: 2;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.2);
	/* margin-left: auto;
    margin-right: auto; */
	align-content: center;
}

.uniqcard {
	display: block;
	margin: auto;
	position: relative;
	top: 20px;
	z-index: 3;
	padding: 5px;
	font-family: 'Dosis';
	font-weight: 300;
	font-size: 1.2rem;
	width: 90vw;
	min-height: 100px;
	border: 2px solid #9a0007;
	background-color: #ffffff;
	border-radius: 5px;
	-webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
	justify-content: center;
	text-align: center;
}

.uniqcard .closeBtnContainer {
	text-align: right;
	padding-bottom: 10px;
	padding-right: 10px;
}

.uniqcard .closeBtn {
	margin: 0;
	padding: 0;
	text-align: center;
	line-height: 0.6em;
	align-self: left;
	display: inline-block;
	padding: 0px 5px;
	font-family: 'Dosis';
	font-weight: 500;
	font-size: 2rem;
	cursor: pointer;
	color: #9a0007;
}

.uniqcard .uniqImg {
	/*  */
	height: 500px;
	width: auto;
	object-fit: cover;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.uniqcard h3 {
	margin: 20px 0 20px 0;
	padding: 0;
	font-weight: 500;
}

.uniqcardp {
	text-justify: inter-word;
	margin: 10px 0 20px 0;
	padding: 0 50px;
	font-weight: 300;
}

.biocardp {
	text-justify: inter-word;
	margin: 10px 0 10px 0;
	padding: 0;
	font-weight: 300;
}

.linkContainer {
	margin: 10px 0;
}

a:link {
	margin: 0;
	padding: 0;
	color: #9a0007;
	font-weight: 400;
	background-color: transparent;
	/* z-index: 3; */
}

a:visited {
	color: #770007;
}

a:hover {
	font-weight: 600;
}

.iframecontainer {
	margin: 0;
	position: relative;
	padding-bottom: 60%;
	height: 0;
	overflow: hidden;
	border-radius: 4px;
}

.iframecontainer iframe {
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #ddd;
}

.uniqcard .bioImg {
	height: 200px;
	width: auto;
	border-radius: 100%;
	border: 1px solid #ddd;
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
	.uniqcard {
		top: 15px;
	}
	.uniqcard .bioImg {
		height: 150px;
	}
	.biocardp,
	.uniqcardp {
		font-size: 1.2rem;
	}
}

@media only screen and (max-width: 440px) {
	.uniqcard {
		top: 5px;
	}
	.uniqcard .bioImg {
		height: 120px;
	}
	.biocardp,
	.uniqcardp {
		font-size: 1rem;
	}
}
