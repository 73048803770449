.cardBody {
	list-style: none;
	margin: 0;
	padding: 0;
	padding: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

.cardBody li {
	display: inline-block;
	align-self: center;
}
