body {
	font-family: 'Dosis', 'Droid Sans', sans-serif;
	margin: 0;
	padding: 0;
	padding-bottom: 50px;
	overflow-x: hidden;
	font-weight: 200;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
