body {
	font-family: 'Dosis', 'Droid Sans', sans-serif;
	margin: 0;
	padding: 0;
	padding-bottom: 50px;
	overflow-x: hidden;
	font-weight: 200;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  margin: 0;
  padding: 0;
  font-size: 1.5em;
}
.logo {
    color: #9a0007;
    font-family: 'Dosis';
    font-weight: 400;
    display: inline;
    padding: 2px;
    font-size: 2.5rem;
    border: 3px solid #9a0007;
    position: relative;
    top: -13px;
}
.name {
	font-family: 'Dosis';
	display: inline-block;
	padding-left: 5px;
	line-height: 0.6em;
}

.name h1 {
	font-weight: 300;
	font-size: 2rem;
}

.name h2 {
	color: #9a0007;
	font-weight: 300;
	display: block;
	font-size: 1.5rem;
	padding-top: 2px;
}

@media only screen and (max-width: 440px) {
	.name {
		padding-left: 5px;
		line-height: 0.7em;
	}
	.name h1 {
		font-size: 1.6rem;
	}
	.name h2 {
		font-size: 1rem;
		padding-top: 2px;
	}
}

.header_container {
	padding: 20px 30px;
}

.header_content {
	padding-bottom: 30px;
}

@media only screen and (max-width: 440px) {
	.header_container {
		padding: 20px 20px;
	}
	.header_content {
		padding-bottom: 10px;
	}
}

.navbar {
	display: flex;
	justify-content: space-between;
	margin-left: 25px;
	margin-right: 25px;
	margin-bottom: 25px;
}

.navbar div {
	font-family: 'Dosis';
	font-weight: 300;
	font-size: 1.5rem;
	cursor: pointer;
}

.navbar div:hover {
	padding: 0;
	color: #9a0007;
	font-weight: 500;
}

.sites {
	/*  */
	padding-right: 2px;
	padding-left: 2px;
}

.sites:hover,
.sites:active {
	/*  */
	padding: 0;
	color: #9a0007;
	font-weight: 500;
}

.games {
	/*  */
	padding-right: 3px;
	padding-left: 4px;
}

.games:hover,
.games:active {
	/*  */
	padding: 0;
	color: #9a0007;
	font-weight: 500;
}

.writing {
	/*  */
	padding-right: 4px;
	padding-left: 5px;
}

.writing:hover,
.writing:active {
	/*  */
	padding: 0;
	color: #9a0007;
	font-weight: 500;
}

.about {
	/*  */
	padding-right: 3px;
	padding-left: 4px;
}

.about:hover,
.about:active {
	/*  */
	padding: 0;
	color: #9a0007;
	font-weight: 500;
}

@media only screen and (max-width: 900px) {
	.sliderPicture {
		height: 292px;
		width: 532px;
	}
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
	.sliderPicture {
		height: 182px;
		width: 332px;
	}
}

@media only screen and (max-width: 440px) {
	.navbar {
		flex-direction: column;
		margin-bottom: 0;
		text-align: right;
	}
	.navbar div {
		font-size: 1.4rem;
		margin-bottom: 15px;
	}
}

.card {
	margin: 20px 10px;
	padding: 10px;
	font-family: 'Dosis';
	font-weight: 300;
	font-size: 1.2rem;
	cursor: pointer;
	width: 400px;
	min-height: 100px;
	border: 2px solid #9a0007;
	background-color: #ffffff;
	border-radius: 5px;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
	justify-content: center;
	text-align: center;
}

.card:hover {
	border: 3px solid #9a0007;
	margin: 19px 9px;
}

.card img {
	/*  */
	width: 380px;
	height: 200px;
	/* -webkit-filter: grayscale(100%); 
    filter: grayscale(100%); */
	object-fit: cover;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.card h3 {
	font-weight: 300;
}

.card p {
	font-weight: 200;
}

@media only screen and (max-width: 440px) {
	.card {
		font-size: 1rem;
		width: 290px;
	}
	.card img {
		width: 280px;
		height: 147px;
	}
}

.sliderItem {
	position: absolute;
	color: white;
	left: 0;
	right: 0;
	margin: auto;
	height: 400px;
	width: 730px;
	object-fit: contain;
	object-position: 50% 50%;
	border: 1px solid #eee;
	border-radius: 4px;
}

.sliderItem.visible {
	opacity: 1;
	-webkit-transition: opacity 1s ease-in;
	transition: opacity 1s ease-in;
}

.sliderItem.invisible {
	opacity: 0;
	-webkit-transition: opacity 1s ease-in;
	transition: opacity 1s ease-in;
}

@media only screen and (max-width: 900px) {
	.sliderItem {
		height: 290px;
		width: 530px;
	}
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
	.sliderItem {
		height: 180px;
		width: 330px;
	}
}

@media only screen and (max-width: 440px) {
	.sliderItem {
		height: 250px;
		width: 250px;
	}
}

.sliderContainer {
	display: inline-block;
	position: relative;
}

.sliderPicture {
	display: block;
	position: relative;
	height: 402px;
	width: 732px;
	overflow: hidden;
	text-align: center;
	justify-content: center;
}

.arrow {
	background-color: #9a0007;
}

i {
	position: absolute;
	top: 49%;
	border: solid #9a0007;
	height: 15px;
	width: 15px;
	border-width: 0 6px 6px 0;
	display: inline-block;
	padding: 3px;
	border-radius: 5px;
	cursor: pointer;
}

.right {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	right: -20px;
}

.left {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	left: -20px;
}

@media only screen and (max-width: 900px) {
	.sliderPicture {
		height: 292px;
		width: 532px;
	}
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
	.sliderPicture {
		height: 182px;
		width: 332px;
	}
}

@media only screen and (max-width: 440px) {
	.sliderPicture {
		height: 252px;
		width: 252px;
	}
}

.uniqcardContainer {
	display: block;
	position: fixed;
	z-index: 2;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.2);
	/* margin-left: auto;
    margin-right: auto; */
	align-content: center;
}

.uniqcard {
	display: block;
	margin: auto;
	position: relative;
	top: 20px;
	z-index: 3;
	padding: 5px;
	font-family: 'Dosis';
	font-weight: 300;
	font-size: 1.2rem;
	width: 90vw;
	min-height: 100px;
	border: 2px solid #9a0007;
	background-color: #ffffff;
	border-radius: 5px;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
	justify-content: center;
	text-align: center;
}

.uniqcard .closeBtnContainer {
	text-align: right;
	padding-bottom: 10px;
	padding-right: 10px;
}

.uniqcard .closeBtn {
	margin: 0;
	padding: 0;
	text-align: center;
	line-height: 0.6em;
	align-self: left;
	display: inline-block;
	padding: 0px 5px;
	font-family: 'Dosis';
	font-weight: 500;
	font-size: 2rem;
	cursor: pointer;
	color: #9a0007;
}

.uniqcard .uniqImg {
	/*  */
	height: 500px;
	width: auto;
	object-fit: cover;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.uniqcard h3 {
	margin: 20px 0 20px 0;
	padding: 0;
	font-weight: 500;
}

.uniqcardp {
	text-justify: inter-word;
	margin: 10px 0 20px 0;
	padding: 0 50px;
	font-weight: 300;
}

.biocardp {
	text-justify: inter-word;
	margin: 10px 0 10px 0;
	padding: 0;
	font-weight: 300;
}

.linkContainer {
	margin: 10px 0;
}

a:link {
	margin: 0;
	padding: 0;
	color: #9a0007;
	font-weight: 400;
	background-color: transparent;
	/* z-index: 3; */
}

a:visited {
	color: #770007;
}

a:hover {
	font-weight: 600;
}

.iframecontainer {
	margin: 0;
	position: relative;
	padding-bottom: 60%;
	height: 0;
	overflow: hidden;
	border-radius: 4px;
}

.iframecontainer iframe {
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #ddd;
}

.uniqcard .bioImg {
	height: 200px;
	width: auto;
	border-radius: 100%;
	border: 1px solid #ddd;
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
	.uniqcard {
		top: 15px;
	}
	.uniqcard .bioImg {
		height: 150px;
	}
	.biocardp,
	.uniqcardp {
		font-size: 1.2rem;
	}
}

@media only screen and (max-width: 440px) {
	.uniqcard {
		top: 5px;
	}
	.uniqcard .bioImg {
		height: 120px;
	}
	.biocardp,
	.uniqcardp {
		font-size: 1rem;
	}
}

.cardBody {
	list-style: none;
	margin: 0;
	padding: 0;
	padding: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

.cardBody li {
	display: inline-block;
	align-self: center;
}

