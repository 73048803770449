.header_container {
	padding: 20px 30px;
}

.header_content {
	padding-bottom: 30px;
}

@media only screen and (max-width: 440px) {
	.header_container {
		padding: 20px 20px;
	}
	.header_content {
		padding-bottom: 10px;
	}
}
