.name {
	font-family: 'Dosis';
	display: inline-block;
	padding-left: 5px;
	line-height: 0.6em;
}

.name h1 {
	font-weight: 300;
	font-size: 2rem;
}

.name h2 {
	color: #9a0007;
	font-weight: 300;
	display: block;
	font-size: 1.5rem;
	padding-top: 2px;
}

@media only screen and (max-width: 440px) {
	.name {
		padding-left: 5px;
		line-height: 0.7em;
	}
	.name h1 {
		font-size: 1.6rem;
	}
	.name h2 {
		font-size: 1rem;
		padding-top: 2px;
	}
}
