.logo {
    color: #9a0007;
    font-family: 'Dosis';
    font-weight: 400;
    display: inline;
    padding: 2px;
    font-size: 2.5rem;
    border: 3px solid #9a0007;
    position: relative;
    top: -13px;
}